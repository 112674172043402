import { Grid, LinearProgress } from "@material-ui/core";
import {
	EAcknowledgementSignature,
	formatPhoneNumber,
	getPromoAmountOffFromStripePromo,
} from "amp";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SignaturePad } from "src/components/signature-pad/SignaturePad";
import { AmpButton } from "src/components/ui-core/buttons/AmpButton";
import { AmpCard } from "src/components/ui-core/containers/AmpCard";
import { AmpContainer } from "src/components/ui-core/containers/AmpContainer";
import { AmpCheckbox } from "src/components/ui-core/inputs/AmpCheckbox";
import { AmpDivider } from "src/components/ui-core/spacers/AmpDivider";
import { AmpText } from "src/components/ui-core/typography/AmpText";
import { useFetchData } from "src/hooks/useFetchData";
import { useMembershipPurchaseContext } from "src/sites/membership-purchase/contexts/MembershipPurchaseContext";
import { StripeSetupIntentProvider } from "src/sites/membership-purchase/contexts/StripeSetupIntentContext";
import { usePreviewSubscriptionInvoice } from "src/sites/membership-purchase/hooks/usePreviewSubscriptionInvoice";
import { useTermsAndConditionsAcknowledgement } from "src/sites/membership-purchase/hooks/useTermsAndConditionsAcknowledgement";
import { useTermsAndHelpLink } from "src/sites/membership-purchase/hooks/useTermsAndHelpLink";
import { toDollars } from "src/utils/toDollars";
import { PaymentBottomSheet } from "./PaymentBottomSheet";
import { PromoCodeModal } from "./PromoCodeModal";
import { ReferralCodeModal } from "./ReferralCodeModal";
import { useGetReferralCodeDiscount } from "../hooks/useGetReferralCodeDiscount";
import { AmpIconButton } from "src/components/ui-core/buttons/AmpIconButton";
import Stripe from "stripe";

export const ProductPaymentScreen = ({
	setupIntentClientSecret,
	priceId,
	onGoBack,
}: {
	setupIntentClientSecret: string;
	priceId: number;
	onGoBack: () => void;
}) => (
	<StripeSetupIntentProvider setupIntentSecret={setupIntentClientSecret}>
		<PaymentScreen priceId={priceId} onGoBack={onGoBack} />
	</StripeSetupIntentProvider>
);

const PaymentScreen = ({
	priceId,
	onGoBack,
}: {
	priceId: number;
	onGoBack: () => void;
}) => {
	// STATES
	const [acknowledgementAccepted, setAcknowledgementAccepted] = useState(false);
	const [signatureBase64, setSignatureBase64] = useState<string | undefined>();
	const [showPaymentModal, setShowPaymentModal] = useState(false);

	const [showPromoCodeModal, setShowPromoCodeModal] = useState(false);
	const [paymentPromoCode, setPaymentPromoCode] = useState<
		Stripe.PromotionCode | undefined
	>();

	const [showReferralCodeModal, setShowReferralCodeModal] = useState(false);
	const [referralCode, setReferralCode] = useState<string | undefined>();
	// HOOKS
	const getInvoicePreview = usePreviewSubscriptionInvoice();
	const { userData, product } = useMembershipPurchaseContext();
	const { termsAndConditionsAcknowledgement } =
		useTermsAndConditionsAcknowledgement();
	const [termsAndConditionsTermsAndHelpLink] =
		useTermsAndHelpLink("TERMS_&_CONDITIONS");
	const { referralDiscount } = useGetReferralCodeDiscount();
	const normalizedReferralDiscount = useMemo(
		() => (referralDiscount ?? 0) * 100,
		[referralDiscount],
	);

	const getInvoicePreviewCallback = useCallback(async () => {
		if (!userData) return;
		return getInvoicePreview(userData.accountId, priceId, paymentPromoCode?.id);
	}, [userData, priceId, paymentPromoCode, getInvoicePreview]);

	const {
		data: invoicePreview,
		loading,
		retry: refetchInvoice,
	} = useFetchData(getInvoicePreviewCallback);

	const onValidPromoCodeSubmitted = (promoCode: Stripe.PromotionCode) => {
		setPaymentPromoCode(promoCode);
	};

	const onValidReferralCodeSubmitted = (code: string) => {
		setReferralCode(code);
	};

	useEffect(() => {
		refetchInvoice();
	}, [paymentPromoCode, refetchInvoice]);

	const price =
		product?.prices?.find((price) => price.id === priceId)?.metadata.Tiers[0]
			.AmountPerUnit ?? 0;

	const paymentSubtotal = useMemo(() => {
		return (
			(invoicePreview?.invoice?.subtotal_excluding_tax ?? 0) -
			(invoicePreview?.invoice?.total_discount_amounts?.[0]?.amount ?? 0) -
			(referralCode ? normalizedReferralDiscount : 0)
		);
	}, [invoicePreview, referralCode, normalizedReferralDiscount]);

	const paymentTotal = useMemo(() => {
		return (
			(invoicePreview?.invoice?.total ?? 0) -
			(referralCode ? normalizedReferralDiscount : 0)
		);
	}, [invoicePreview, referralCode, normalizedReferralDiscount]);

	return (
		<>
			<AmpContainer width="full" height="full">
				<AmpContainer
					padding={4}
					direction="column"
					alignItems="center"
					width="full"
					color="surface"
					flexGrow={1}
				>
					<Grid container item direction="column" spacing={3} md={6} sm={12}>
						<Grid item>
							<AmpCard padding={6} gap={6} flexDirection="column">
								<AmpText type="body_lg" fontWeight="bold">
									Your Information
								</AmpText>
								<AmpContainer
									direction="row"
									width="full"
									justifyContent="space-between"
								>
									<AmpContainer>
										<AmpText type="body_sm">{userData?.name}</AmpText>
										<AmpText type="body_sm">
											{formatPhoneNumber(userData?.phoneNumber)}
										</AmpText>
										<AmpText type="body_sm">{userData?.email}</AmpText>
									</AmpContainer>
									<AmpContainer>
										<AmpIconButton
											icon="Edit"
											color="primary"
											onClick={onGoBack}
										/>
									</AmpContainer>
								</AmpContainer>
							</AmpCard>
						</Grid>
						<Grid item>
							<AmpCard padding={6} gap={6} flexDirection="column">
								{loading ? (
									<LinearProgress />
								) : (
									<>
										<AmpText type="body_lg" fontWeight="bold">
											{product?.name}
										</AmpText>
										<AmpContainer width="full" gap={2}>
											<PriceRow label="Subtotal:" price={price} bold />
											{paymentPromoCode && (
												<DiscountRow
													label={`Coupon - 
														${getPromoAmountOffFromStripePromo(paymentPromoCode)}
														:`}
													price={
														invoicePreview?.invoice?.total_discount_amounts?.[0]
															?.amount ?? 0
													}
													onRemove={() => setPaymentPromoCode(undefined)}
												/>
											)}
											{referralCode && (
												<DiscountRow
													label={`Referral Code - ${referralCode}:`}
													price={normalizedReferralDiscount}
													onRemove={() => setReferralCode(undefined)}
												/>
											)}
											{(paymentPromoCode || referralCode) && (
												<PriceRow
													label="Pre-Tax Amount:"
													price={paymentSubtotal}
													bold
												/>
											)}
											<AmpDivider />
											<PriceRow
												label="Tax:"
												price={invoicePreview?.invoice?.tax ?? 0}
											/>
											<PriceRow label="Total:" price={paymentTotal} bold />
										</AmpContainer>
										{(!paymentPromoCode || !referralCode) && (
											<AmpContainer>
												{!paymentPromoCode && (
													<div onClick={() => setShowPromoCodeModal(true)}>
														<AmpText
															type="body_sm"
															color="primary"
															fontWeight="bold"
														>
															Add Promo Code
														</AmpText>
													</div>
												)}
												{!referralCode && (referralDiscount ?? 0) > 0 && (
													<div onClick={() => setShowReferralCodeModal(true)}>
														<AmpText
															type="body_sm"
															color="primary"
															fontWeight="bold"
														>
															Add Referral Code
														</AmpText>
													</div>
												)}
											</AmpContainer>
										)}
										<AmpDivider />
										<PriceRow label="Due Today" price={paymentTotal} bold />
									</>
								)}
							</AmpCard>
						</Grid>
						<Grid item>
							<AmpCard padding={6} gap={6}>
								<AmpText type="body_sm">
									By{" "}
									{termsAndConditionsAcknowledgement?.signature_type ===
									EAcknowledgementSignature.Written
										? "signing"
										: "checking the box"}{" "}
									below you agree to our{" "}
									<a
										href={termsAndConditionsTermsAndHelpLink?.url__c}
										target="_blank"
										rel="noreferrer"
									>
										terms and conditions
									</a>
								</AmpText>
								{termsAndConditionsAcknowledgement?.signature_type ===
								EAcknowledgementSignature.Written ? (
									<AmpContainer width="full" alignItems="center">
										<SignaturePad
											onPadContentChange={(isEmpty) =>
												setAcknowledgementAccepted(!isEmpty)
											}
											onEnd={(base64ImageData) =>
												setSignatureBase64(base64ImageData)
											}
										/>
									</AmpContainer>
								) : (
									<AmpCheckbox
										checked={acknowledgementAccepted}
										onChange={(_, checked) =>
											setAcknowledgementAccepted(checked)
										}
										name=""
										color="secondary"
										label="Accept Terms and Conditions"
									/>
								)}
							</AmpCard>
						</Grid>
					</Grid>
					{userData?.stripeCustomerId && acknowledgementAccepted && (
						<PaymentBottomSheet
							customerId={userData?.stripeCustomerId}
							customerSignature={signatureBase64}
							priceId={priceId}
							open={showPaymentModal}
							referralCode={referralCode}
							promoCodeId={paymentPromoCode?.id}
							onClose={() => setShowPaymentModal(false)}
						/>
					)}
				</AmpContainer>
				<AmpContainer
					color="white"
					width="full"
					position="sticky"
					positionLocationSpacing={{ location: "bottom", spacing: 0 }}
				>
					<AmpDivider />
					<AmpContainer width="full" alignItems="center" padding={4}>
						<Grid container direction="row" spacing={2} md={6} sm={12}>
							<Grid item xs={4}>
								<AmpButton
									label="Back"
									colorType="primary"
									type="outlined"
									onClick={onGoBack}
									width="wide"
								/>
							</Grid>
							<Grid item xs={8}>
								<AmpButton
									label="Checkout"
									disabled={!acknowledgementAccepted}
									onClick={() => setShowPaymentModal(true)}
									width="wide"
									colorType="secondary"
								/>
							</Grid>
						</Grid>
					</AmpContainer>
				</AmpContainer>
			</AmpContainer>
			<PromoCodeModal
				isOpen={showPromoCodeModal}
				onClose={() => setShowPromoCodeModal(false)}
				invoiceSubtotal={invoicePreview?.invoice?.subtotal_excluding_tax ?? 0}
				priceId={priceId}
				onValidPromoCodeSubmitted={onValidPromoCodeSubmitted}
			/>
			<ReferralCodeModal
				isOpen={showReferralCodeModal}
				onClose={() => setShowReferralCodeModal(false)}
				onValidReferallCodeSubmitted={onValidReferralCodeSubmitted}
			/>
		</>
	);
};

const DiscountRow = ({
	label,
	price,
	onRemove,
}: {
	label: string;
	price: number;
	onRemove: () => void;
}) => {
	return (
		<AmpContainer
			direction="row"
			justifyContent="space-between"
			width="full"
			alignItems="center"
		>
			<AmpText type="body_sm">{label}</AmpText>
			<AmpContainer direction="row" alignItems="center">
				<AmpText type="body_sm">({toDollars(price)})</AmpText>
				<AmpIconButton size="small" icon="Close" onClick={onRemove} />
			</AmpContainer>
		</AmpContainer>
	);
};

const PriceRow = ({
	label,
	price,
	bold,
}: {
	label: string;
	price: number;
	bold?: boolean;
}) => (
	<AmpContainer
		direction="row"
		justifyContent="space-between"
		width="full"
		alignItems="center"
	>
		<AmpText type="body_sm" fontWeight={bold ? "bold" : "normal"}>
			{label}
		</AmpText>
		<AmpText type="body_sm" fontWeight={bold ? "bold" : "normal"}>
			{toDollars(price)}
		</AmpText>
	</AmpContainer>
);
